:root {
    --brightaqua: #7bddd2;
    --aqua: #4ec0b3;
    --yellow: #e9c46a;
    --brightorange: #f8b37a;
    --orange: #f4a261;
    --red: #e76f51;
}

@font-face {
    font-family: "Bungee";
    src: local("Bungee"),
        url("./fonts/Bungee/Bungee-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "BungeeShade";
    src: local("Bungee_Shade"),
        url("./fonts/Bungee_Shade/BungeeShade-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "RobotoSlab";
    src: local("Roboto_Slab"),
        url("./fonts/Roboto_Slab/RobotoSlab-VariableFont_wght.ttf")
            format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "VT323";
    src: local("VT323-Regular"),
        url("./fonts/VT323/VT323-Regular.ttf") format("truetype");
    font-weight: normal;
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

@keyframes bounce {
    0% {
        transform: scale(1, 1) translateY(0);
    }
    10% {
        transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
        transform: scale(0.9, 1.1) translateY(-30px);
    }
    50% {
        transform: scale(1.05, 0.95) translateY(0);
    }

    70% {
        transform: scale(1, 1) translateY(0);
    }
    100% {
        transform: scale(1, 1) translateY(0);
    }
}

@keyframes color-change {
    from {
        color: white;
    }
    to {
        color: var(--orange);
    }
}

@keyframes background-color-change {
    from {
        color: var(--aqua);
    }
    to {
        color: var(--orange);
    }
}

body {
    background-color: black;
}

a {
    color: white;
}
a:hover {
    text-decoration: none;
    color: white;
}

.project {
    background-color: #38383d;
    border-radius: 10px;
}

.navbar {
    background-color: black;
}

.nav-title-letter {
    font-family: "BungeeShade";
    color: white;
}

.bounce:hover {
    animation: bounce 0.9s ease-in-out, color-change 0.5s ease-in-out;
}

.link {
    color: white;
    font-size: 2rem;
}
.link:hover {
    animation: color-change 0.1s ease-in-out;
    color: var(--orange);
}

.header {
    font-size: 3rem;
}

.name {
    color: var(--aqua);
}

.role {
    font-weight: 600;
    font-size: 4rem;
}
.role:hover {
    animation: color-change 0.3s ease-in-out;
    color: var(--orange);
}

.decoration {
    background-image: url("/src/images/code.png");
    background-repeat: no-repeat;
    opacity: 0.5;
}

.aqua-border {
    border: solid;
    border-color: var(--aqua);
    border-bottom: 2px;
    width: 10px;
}

.gray {
    color: #404040;
}

.code-button {
    background-color: var(--aqua);
    transition: 0.3s;
}

.code-button:hover {
    background-color: var(--brightaqua);
}

.live-button {
    background-color: var(--orange);
    transition: 0.3s;
}

.live-button:hover {
    background-color: var(--brightorange);
}
